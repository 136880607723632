import { AuthContext } from "@/context/AuthProvider";
import { useRoles } from "@/hooks/firestore/use-roles";
import { auth, db } from "@/services/firebase";
import { NFApp } from "@/types/app";
import { UserRoles } from "@/types/user";
import { signOut } from "firebase/auth";
import {
  collection,
  DocumentData,
  DocumentReference,
  orderBy,
  query,
} from "firebase/firestore";
import { Link, navigate } from "gatsby";
import React, { useContext, useEffect, useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { useMediaQuery } from "usehooks-ts";
import NavDesktop from "./nav/nav-desktop";
import NavMobile from "./nav/nav-mobile";

export interface NavProps {
  apps: NFApp[];
  userMenuItems: IUserMenuItem[];
  logout: () => void;
}

export interface IUserMenuItem {
  title: string;
  location: string;
}

const userMenuItems: IUserMenuItem[] = [
  { title: "Profil", location: "/profil" },
];

export default function Header() {
  const { user, setUser, userLoading } = useContext(AuthContext);

  const [roles, setRoles, rolesLoading] = useRoles();
  const [appsRaw, loadingAppsRaw] = useCollection(
    query(collection(db, "apps"), orderBy("order"))
  );
  const [apps, setApps] = useState<NFApp[]>([]);

  const isDesktop = useMediaQuery("(min-width: 1380px)", {
    initializeWithValue: false,
  });

  const isTiny = useMediaQuery("(max-width: 300px)", {
    initializeWithValue: false,
  });

  useEffect(() => {
    if (!rolesLoading && roles) {
      if (!loadingAppsRaw && appsRaw) {
        var tmpApps: NFApp[] = [];

        appsRaw.forEach((appDoc) => {
          var appData = appDoc.data();
          var tmpApp: NFApp = {
            uid: appDoc.id,
            name: appData.name,
            displayName: appData.displayName,
            description: appData.description,
            icon: appData.icon,
            link: appData.link,
            roles: [],
            order: appData.order,
            isActive: appData.isActive,
          };

          var tmpRoles: UserRoles[] = [];
          appData.roles.forEach((roleReference: DocumentReference) => {
            roles.forEach(
              (
                value: DocumentReference<DocumentData, DocumentData>,
                key: UserRoles
              ) => {
                if (roleReference.id === value.id) {
                  tmpRoles.push(key);
                }
              }
            );
          });

          tmpApp.roles = tmpRoles;
          tmpApps.push(tmpApp);
        });
        setApps(tmpApps);
      }
    }
  }, [roles, rolesLoading, appsRaw, loadingAppsRaw]);

  const logout = () => {
    signOut(auth);
    navigate("/");
  };

  return (
    <div className="h-16 bg-primary pl-48 pr-48 flex w-full max-2xl:pl-32 max-2xl:pr-32 max-xl:pl-4 max-xl:pr-0">
      <Link to="/" className="flex">
        <img
          src="/images/logo.png"
          alt="Nick Fischer Logo"
          className="pt-2 pb-2 h-16"
        />
        {!isTiny && (
          <h1 className="text-neutral-50 font-normal text-3xl ml-4 whitespace-nowrap flex items-center mt-0 mb-0 no-underline max-md:text-2xl">
            Nick Fischer
          </h1>
        )}
      </Link>

      <div className="flex ml-auto">
        {isDesktop ? (
          <NavDesktop
            apps={apps}
            userMenuItems={userMenuItems}
            logout={logout}
          />
        ) : (
          <NavMobile
            apps={apps}
            userMenuItems={userMenuItems}
            logout={logout}
          />
        )}
      </div>
    </div>
  );
}
